import React from "react"
//import {Link} from "gatsby"
import Layout from "../components/layout/layout.higherComponent"
import Head from "../components/head"

const Resources = () => {
  return (
    <div>
      <Layout>
        <Head title="Resources" />
        <div className="container-fluid" style={{ paddingTop: "5rem" }}>
          <h2>Under construction </h2>
          <p> Coming soon</p>
        </div>
      </Layout>
    </div>
  )
}

export default Resources
